import React from "react"

// Nested component imports

const SubmitButtonComponent = ({ className, children, disabled }) => {
  return (
    <button type="submit" className={className} disabled={disabled}>
      Submit
    </button>
  )
}

export default SubmitButtonComponent
