import styled from "styled-components";

const FormDesktop = styled.div`
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  padding: 0px 64px 64px 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 800px) {
    border-radius: 24px;
    padding: 32px 24px;
    align-items: flex-start;
    padding: 32px 24px;
  }
`;

export { FormDesktop }
