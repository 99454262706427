import React, { useState } from "react";
import { useLocation } from '@reach/router';
import styled from "styled-components";
import FormFieldLabelled from "./FormFieldLabelledComponent";
import SubmitButtonComponent from "./SubmitButtonComponent";
import axios from "axios";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { FormDesktop } from "./common/FormDesktop.styled";

export const Title = styled.p`
  width: 384px;
  font-family: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.lineHeight};
  color: rgba(0, 51, 61, 1);
  margin-bottom: 16px;

  @media only screen and (max-width: 800px) {
    max-width: 318px;
  }
`;
export const Titleemphasis2 = styled.strong`
  font-family: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop2HeadingXlBlackDefaultLeft.lineHeight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`;
export const Paragraph = styled.p`
  max-width: 384px;
  font-family: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.family};
  font-size: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktopTextLargeBlackDefaultLeft.lineHeight};
  color: rgba(0, 51, 61, 1);
  margin-bottom: 24px;
  opacity: 0.7;

  @media only screen and (max-width: 800px) {
    max-width: 278px;
  }
`;
export const FormFieldLabelledItem = styled(FormFieldLabelled)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const CheckboxText = styled.p`
  max-width: 347px;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.gray};
  text-decoration: underline;
`;
export const SuccessMessage = styled.span`
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.gray};
`;

export const FailureMessage = styled(SuccessMessage)`
  color: ${(props) => props.theme.textColors.red};
`;

export const SubmitBtn = styled(SubmitButtonComponent)`
  background-color: ${(props) => props.theme.colors.navyPrimary};
  border-radius: 4px;
  padding: 11px 16px 13px;
  display: flex;
  align-items: flex-start;
  font-family: ${(props) => props.theme.fonts.buttonLeftWhite.family};
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  font-weight: ${(props) => props.theme.fonts.buttonLeftWhite.weight};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${(props) => props.theme.textColors.white};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const FormDesktopComponent = ({ className }) => {
  const RECAPTCHA_SITE_KEY = "6LcUzEIdAAAAAATvn6WXT4cDDkFiKL68sSQJuz0d";
  const FORM_POST_URL = "/post-contact-form";
  const submitUrl = useLocation().origin+ "/#formPanel";
  let token = '';
  const [submitState, setSubmitState] = useState({
    submitting: false,
    success: null,
    statusMessage: null,
  });

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.target;
    setSubmitState({ submitting: true });
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);

    axios({ method: "post", url: FORM_POST_URL, data })
      .then((r) => {
        handleServerResponse(
          true,
          "Thank you. Your submission has been received!",
          form
        );
      })
      .catch((error) => {
        handleServerResponse(
          false,
          "There was an error submitting your request. Please try again later!",
          form
        );
      });
  };

  const handleServerResponse = (success, msg, form) => {
    setSubmitState({
      submitting: false,
      success: success,
      statusMessage: msg,
    });
    setTimeout(() => setSubmitState({ statusMessage: null }), 5 * 1000);
    if (success) {
      form.reset();
    }
  };

  return (
    <div id="formPanel">
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <form name="contact" onSubmit={submitHandler}>
          <input type="hidden" name="form-name" value="contact" />
          <FormDesktop className={className}>
            <Title>
              Request your free <br />
              <Titleemphasis2> no-obligation trial today</Titleemphasis2>
            </Title>
            <Paragraph>
              Fill out the below form to receive product updates and find out if
              deBa is a good fit for you.
            </Paragraph>
            <FormFieldLabelledItem
              placeholder="First Name"
              label="First Name"
              name="ctl_firstname"
              pattern=".*[^ ].*"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="Last name"
              label="Last Name"
              name="ctl_lastname"
              pattern=".*[^ ].*"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="Company"
              label="Company"
              name="ctl_company"
            />
            <FormFieldLabelledItem
              placeholder="john@email.com"
              label="Email"
              name="ctl_email"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required={true}
            />
            <FormFieldLabelledItem
              placeholder="0412 345 687"
              label="Phone"
              name="ctl_phone"
            />
            <input type="hidden" name="action" value="validate_captcha" />
            <input
              type="hidden"
              id="hdn_cs_submiturl"
              name="hdn_cs_submiturl"
              value={submitUrl}
            />
            <input
              type="hidden"
              id="hdn_cs_notify"
              name="hdn_cs_notify"
              value="true"
            />
            <input type="hidden" name="hdn_cs_templateid" value="undefined" />
            <input
              type="hidden"
              name="hdn_cs_id"
              value="86387675-6b3b-4aff-a370-3813a26e66c1"
            />
            <SubmitBtn disabled={submitState.submitting} />
            {submitState.success ? <SuccessMessage>{submitState.statusMessage}</SuccessMessage> : <FailureMessage>{submitState.statusMessage}</FailureMessage>}

            <GoogleReCaptcha
              onVerify={(t) => {
                token = t
              }}
            />
          </FormDesktop>
        </form>
      </GoogleReCaptchaProvider>
    </div>
  );
};
export default FormDesktopComponent;
