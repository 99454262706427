import React from "react";
import styled from "styled-components";

// For styling any nested component, Overlay use the styled method.
// This method works perfectly on all of your any third-party component, as long as they attach the passed className prop to a DOM element.
// Here an example: https://gist.github.com/Miniplop/80b042d1b44293329ef7332fd47b770c

// Nested component imports
import FormDesktop from "./components/FormDesktopComponent";

export const FormPanelDesktop = styled.div`
  padding: 139px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/images/contact-form-background-cropped.png");
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (max-width: 800px) {
    padding: 0px 0px 50px;
    padding-top: 50px;
  }
`;

export const GreenBackground = styled.div`
  width: 1680px;
  height: 124px;
  background-color: ${(props) => props.theme.colors.greenBackground};
  margin-bottom: 141px;

  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
`;

export const FormDesktopItem = styled(FormDesktop)``;

const FormPanelDesktopComponent = ({ showGreen = true }) => {
  return (
    <FormPanelDesktop id="contact-form" showGreen={showGreen}>
      <FormDesktopItem />
    </FormPanelDesktop>
  );
};

export default FormPanelDesktopComponent;
