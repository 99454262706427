import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { format } from "date-fns";
import { Link } from "gatsby";

const Blog = ({
  title,
  published_date,
  featured_image,
  slug,
  subtitle,
  tags,
  isFeatured = false,
}) => (
  <LinkWrapper to={`/blogs/${slug}`}>
    <Wrapper isFeatured={isFeatured}>
      <ContentWrapper isFeatured={isFeatured}>
        <Content isFeatured={isFeatured}>
          <Top>
            <TagHolder>
              {tags?.map((t) => (
                <Tag>{t}</Tag>
              ))}
            </TagHolder>
            <PublishDate>{format(new Date(published_date), "PPP")}</PublishDate>
          </Top>
          <Title>{RichText.asText(title)}</Title>
          <BlogContent>{RichText.asText(subtitle)}</BlogContent>
        </Content>
      </ContentWrapper>
      <ImageWrapper isFeatured={isFeatured}>
        <img src={featured_image.url} width="100%" height="100%" alt={featured_image.title} />
      </ImageWrapper>
    </Wrapper>
  </LinkWrapper>
);

export const LinkWrapper = styled(Link)`
  text-decoration: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  font-family: Helvetica;
  display: flex;
  ${({ isFeatured }) =>
    isFeatured
      ? `max-height: 285px`
      : `min-height:380px; flex-direction: column-reverse`};
  :hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-family: Agenda;
  margin-bottom: 24px;
  letter-spacing: 0;
  line-height: 37px;
  color: ${({ theme }) => theme.colors.navyPrimary};
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
`;

const PublishDate = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  opacity: 0.6;
`;

export const Content = styled.div`
  flex: 1;
  ${({ isFeatured }) =>
    isFeatured ? `padding: 42px 40px` : `padding: 16px 16px 21px`};
`;
export const ImageWrapper = styled.div`
  img {
    object-fit: cover;
    background-position: center;
    background-size: cover;
  }

  ${({ isFeatured }) => (isFeatured ? `flex: 1;` : `height: 190px`)}
`;
const Top = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const TagHolder = styled.div`
  max-width: 70%;
  display: flex;
`;

const BlogContent = styled.p`
  margin-top: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  opacity: 0.6;
  line-height: 20px;
`;

const Tag = styled.p`
  color: ${({ theme }) => theme.colors.green};
  margin-right: 8px;
`;

export default Blog;
